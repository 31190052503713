// export const base_url = `http://localhost:4001`;
// export const uploads_url = `http://localhost:4001/uploads/`;
// export const api_url = `http://localhost:4001/api`;

// export const base_url = `https://convince.jooodah.com/`;
// export const uploads_url = `https://convince.jooodah.com/uploads/`;
// export const api_url = `https://convince.jooodah.com/api`;

// //
export const base_url = `https://dashboard.jooodah.com`;
export const uploads_url = `https://dashboard.jooodah.com/uploads/`;
export const api_url = `https://dashboard.jooodah.com/api`;

// export const base_url = `https://salesdashboard.jooodah.com`;
// export const uploads_url = `https://salesdashboard.jooodah.com/uploads/`;
// export const api_url = `https://salesdashboard.jooodah.com/api`;

export const sales_dashboard_url = `https://salesdashboard.jooodah.com`;
export const jooodah_dashboard_url = `https://dashboard.jooodah.com`;
